/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Proza+Libre&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Karla", sans-serif;
  height: 100% !important;
  font-weight: 500;
}

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h4 {
    @apply font-lucky text-3xl font-semibold;
  }
}

.text-nowrap {
  text-wrap: nowrap;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 2s infinite;
}

.shape-fill {
  fill: #ffffff;
}

.shape-fill-primary {
  fill: #502a72;
}

.shape-fill-alternate {
  fill: #ab62a7;
}

.shape-fill-secondary {
  fill: rgb(250, 245, 242);
}

.shape-fill-dark {
  fill: #333538;
}

.shape-fill-light-gray {
  fill: rgb(187, 191, 196);
}

.vide-pl-icon {
  fill: white;
}

.carousel-bg-gray {
  background-color: gray !important;
}

.faq-container p ul {
  list-style-type: disc !important;
}

.animation-fill-mode-forwards {
  animation-fill-mode: forwards;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

.animation-delay-900 {
  animation-delay: 900ms;
}

@property --happyStar {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.happy-star-counter {
  counter-reset: happyStar var(--happyStar);
  animation: animate 2s forwards ease;
}

.happy-star-counter:before {
  content: counter(happyStar);
}

@property --shootingStar {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.shooting-star-counter {
  counter-reset: shootingStar var(--shootingStar);
  animation: animate 2s forwards ease;
}

.shooting-star-counter:before {
  content: counter(shootingStar);
}

@property --brightStar {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.bright-star-counter {
  counter-reset: brightStar var(--brightStar);
  animation: animate 2s forwards ease;
}

.bright-star-counter:before {
  content: counter(brightStar);
}

@keyframes animate {
  to {
    --happyStar: 3999;
  }
  to {
    --shootingStar: 8999;
  }
  to {
    --brightStar: 6999;
  }
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating:hover {
  -webkit-animation: rotating 0.1s linear infinite;
  -moz-animation: rotating 0.1s linear infinite;
  -ms-animation: rotating 0.1s linear infinite;
  -o-animation: rotating 0.1s linear infinite;
  animation: rotating 0.1s linear infinite;
}
